import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { Task } from '../_models/task';

@Component({
  selector: 'app-task-in-progress',
  templateUrl: './task-in-progress.component.html',
  styleUrls: ['./task-in-progress.component.css']
})
export class TaskInProgressComponent implements OnInit {

  @Input() task: Task;
  @Output() stopped = new EventEmitter<Task>();
  now: any;

  constructor() {}

  updateTime() {
    let startTime = Date.parse(this.task.start);
    let now = Date.now();

    // console.log(this.task.start);

    let d = new Date(0,0,0,0,0,0,0);
    d.setSeconds((now - startTime) / 1000);

    this.now = d;
  }

  ngOnInit() {
    this.updateTime();
    setInterval(() => { this.updateTime() }, 1000);
  }

  stop(): void {
    this.stopped.emit(this.task);
  }

}
