export class Time {
  id: number;
  tid: number;
  title: string;
  start: string = null;
  finish: string = null;
  startTime: number;
  finishTime: number;
  totalTime: number;
  total: string;

  get startStr(): string {
    return this.start !== null ? this.start.substring(0, this.start.length - 1) : "";
  }
  get finishStr(): string {
    return this.finish !== null ? this.finish.substring(0, this.finish.length - 1) : "";
  }
  set startStr(val: string) {
    this.start = val + "Z";
    this.startTime = Date.parse(this.start);
  }
  set finishStr(val: string) {
    this.finish = val + "Z";
    this.finishTime = Date.parse(this.finish);
  }

  constructor(obj: Time = {} as Time) {
    Object.assign(this, obj);
    this.startTime = Date.parse(this.start);
    this.finishTime = Date.parse(this.finish);
    // this.totalTime = (this.finishTime - this.startTime) / 1000
    // var d = new Date(0,0,0,0,0,0,0);
    // d.setSeconds(this.totalTime);
    // this.total = d;
  }
}
