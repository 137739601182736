import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { ApiRequestTimes } from "../_models/api-request-times";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class TimeService {

  private apiUrl = 'https://tfapi.lookfun.ru';  // URL to web api

  constructor(private http: HttpClient) { }

  getList (tid: number, branch: number, mode: string, startDate: string, finishDate: string): Observable<ApiRequestTimes> {
    let url = this.apiUrl + "/times/list/" + tid + "/" + branch + "?mode=" + mode + "&start=" + startDate + "&finish=" + finishDate;
    let ret = this.http.get<ApiRequestTimes>(url);
    return ret
      .pipe(
        catchError(this.handleError('getList', new ApiRequestTimes()))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }
}
