import {Task} from "./task";

export class ApiRequestTask {
  code: number;
  message: string;
  task: Task;
}

export class ApiRequestTasks {
  code: number;
  message: string;
  tasks: Task[];
  currentTask: Task;
  taskInProgress: Task;
}
