import {Component, Inject} from '@angular/core';
import {MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Time} from '../_models/time';

@Component({
    selector: 'app-task-time-edit',
    templateUrl: 'task-time-edit.component.html',
    styleUrls: ['./task-time-edit.component.css']
})
export class TaskTimeEditComponent {

    constructor(
        public dialogRef: MatDialogRef<TaskTimeEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Time) {}

    onNoClick(): void {
        this.dialogRef.close();
    }
}
