import {Component, Input, OnChanges, SimpleChange} from '@angular/core';
import {Task} from '../_models/task';
import {Time} from '../_models/time';
import {TimeService} from '../_services/time.service';
import {FormControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {TaskTimeEditComponent} from './task-time-edit.component';
import {MatDialog} from '@angular/material/dialog';

export interface KeyVal {
    key: string;
    val: string;
}

@Component({
    selector: 'app-task-time',
    templateUrl: './task-time.component.html',
    styleUrls: ['./task-time.component.css']
})
export class TaskTimeComponent implements OnChanges {

    animal: string;
    name: string;

    @Input() currentTask: Task;
    times: Time[] = [];
    displayedColumns: string[] = ['title', 'start', 'finish', 'total', 'actions'];
    total: string;
    datePipe = new DatePipe('en-US');

    listModes: KeyVal[] = [
        {key: 'list', val: 'List'},
        {key: 'group', val: 'Group'}
    ];

    listMode = 'list';

    startDate = new FormControl(new Date());
    finishDate = new FormControl(new Date());

    constructor(private service: TimeService, public dialog: MatDialog) {
        const date = new Date();
        this.startDate = new FormControl(new Date(date.getFullYear(), date.getMonth(), 1));
        this.finishDate = new FormControl(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    }

    openDialog(time: Time): void {
        const dialogRef = this.dialog.open(TaskTimeEditComponent, {
            width: '300px',
            data: time
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
        });
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // console.log(this.startDate);
        if (changes.currentTask.currentValue && changes.currentTask.currentValue.tid) {
            this.getList(changes.currentTask.currentValue.tid, changes.currentTask.currentValue.branch);
        }
    }

    dataChanged(): void {
        if (this.listMode === 'group') {
            this.displayedColumns = ['title', 'total'];
        } else {
            this.displayedColumns = ['title', 'start', 'finish', 'total', 'actions'];
        }

        this.getList(this.currentTask.tid, this.currentTask.branch);
    }

    getList(tid: number, branch: number): void {
        this.service.getList(
            tid,
            branch,
            this.listMode,
            this.datePipe.transform(this.startDate.value, 'yyyy-MM-dd'),
            this.datePipe.transform(this.finishDate.value, 'yyyy-MM-dd')
        ).subscribe(ret => {
                const _times = [];

                const duration = ret.times.reduce((sum, time: Time) => {
                    // console.log(time);
                    _times.push(new Time(time));
                    let hours, mins, secs;
                    [hours, mins, secs] = time.total.split(':').map(n => parseInt(n, 10));
                    return sum + hours * 60 * 60 + mins * 60 + secs;
                }, 0);

                this.times = _times;

                function pad(number) {
                    return `0${number}`.slice(-2);
                }

                const hours = Math.floor(duration / 3600);
                const minutes = Math.floor(duration % 3600 / 60);
                const seconds = duration % 60;
                const minsSecs = `${pad(minutes)}:${pad(seconds)}`;

                this.total = hours > 0 ? `${hours}:${minsSecs}` : minsSecs;

            });
    }

}
