import {Component, OnInit, Input, OnChanges, SimpleChange} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';

import { Task }         from '../_models/task';
import { TaskService }  from '../_services/task.service';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: [ './task-detail.component.css' ]
})
export class TaskDetailComponent implements OnChanges {
  @Input() currentTask: Task;
  task: Task;

  types: string[] = [
    "Task", "Event", "Folder", "Project", "Pastime"
  ];

  statusList: string[] = [
    "Active", "Resolved", "Closed", "Paused"
  ];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private taskService: TaskService,
    private location: Location
  ) {}

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    this.getTask(changes.currentTask.currentValue.tid);
  }

  getTask(tid: number): void {
      this.taskService.getTask(tid)
        .subscribe(ret => this.task = ret.task);
  }

  goBack(): void {
    this.location.back();
  }

 save(): void {
    this.taskService.updateTask(this.task)
      // .subscribe(() => this.location.back() );
      .subscribe(() => { this.router.navigate(["/tasks/" + this.currentTask.tid + "/edit"]) });
  }
}
