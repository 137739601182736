﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    private apiUrl = 'https://tfapi.lookfun.ru';

    login(email: string, password: string) {
        return this.http.post<any>(`${this.apiUrl}/auth/login`, { email: email, password: password })
            .pipe(map(ret => {
                console.log(ret);
                // login successful if there's a jwt token in the response
                if (ret.code == 1 && ret.user && ret.user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(ret.user));
                }

                return ret.user;
            }));
    }

    register(email: string, password: string, name: string) {
        return this.http.post<any>(`${this.apiUrl}/auth/register`, { email: email, password: password, name: name })
            .pipe(map(ret => {
                console.log(ret);
                // login successful if there's a jwt token in the response
                if (ret.code == 1 && ret.user && ret.user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(ret.user));
                }

                return ret.user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}