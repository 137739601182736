export class Task {
    test = 123;
    tid: number;
    parent: number;
    title: string;
    desc: string;
    type: string;
    status: string;
    start: string = null;
    finish: string = null;

    branch = 0;

    s1 = 0;
    s2 = 0;
    s3 = 0;
    s4 = 0;
    s5 = 0;
    s6 = 0;
    s7 = 0;
    s8 = 0;
    s9 = 0;

    get inProgress(): boolean {
        return this.start !== null && this.finish == null;
    }

    // constructor(title: string, parent: number) {
    //   this.title = title;
    //   this.parent = parent;
    // }

    constructor(obj: Task = {} as Task) {
        Object.assign(this, obj);
        if (this.s1 !== 0) {
            for (let i = 1; i <= 9; i++) {
                if (this['s' + i] !== 0) {
                    this.branch = i;
                }
            }
        }
    }
}
