import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import { Task } from '../_models/task';
import { TaskService } from '../_services/task.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: [ './task-list.component.css' ]
})
export class TaskListComponent implements OnChanges {
  tasks: Task[] = [];
  @Input() currentTask: Task;

  typeIcons = {
    'Event': 'event',
    'Task': 'adjust',
  };

  constructor(private service: TaskService) { }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    if(changes.currentTask.currentValue && changes.currentTask.currentValue.tid)
      this.getList(changes.currentTask.currentValue.tid, changes.currentTask.currentValue.branch);
  }

  getList(tid: number, branch: number): void {
    this.service.getList(tid, branch)
      // .subscribe(tasks => console.log(tasks));
      .subscribe(ret => this.tasks = ret.tasks);
  }
}
