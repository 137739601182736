import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TreeComponent } from './tree/tree.component';
import { TaskSearchComponent } from './task-search/task-search.component';
import { MessagesComponent } from './messages/messages.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule,
  MatToolbarModule,
  MatListModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatCardModule,
  MatSidenavModule,
  MatTableModule,
  MatDatepickerModule, MatNativeDateModule, MAT_DATE_LOCALE,
  MatMenuModule,
  MatDialogModule
} from '@angular/material';

import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, UserService } from './_services';
import { HomeComponent } from './home';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TaskTimeComponent } from './task-time/task-time.component';
import { TaskTimeEditComponent } from './task-time/task-time-edit.component';
import { CatalogComponent } from './catalog/catalog.component';
import { TaskInProgressComponent } from './task-in-progress/task-in-progress.component';
// import { fakeBackendProvider } from './_helpers';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatSidenavModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatMenuModule
  ],
  declarations: [
    AppComponent,
    TaskListComponent,
    TreeComponent,
    TaskDetailComponent,
    MessagesComponent,
    TaskSearchComponent,

    AlertComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    TaskTimeComponent,
    TaskTimeEditComponent,
    CatalogComponent,
    TaskInProgressComponent
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [TaskTimeEditComponent]
})
export class AppModule { }
