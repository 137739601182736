import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TreeComponent } from './tree/tree.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './_guards';
import { TaskTimeComponent } from './task-time/task-time.component';

const routes: Routes = [
  { path: 'detail/:id', component: TaskDetailComponent, canActivate: [AuthGuard] },
  { path: 'tasks/:taskId/:mode', component: TreeComponent, canActivate: [AuthGuard] },
  { path: 'time', component: TaskTimeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: '**', redirectTo: '/tasks/0/edit' },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
