import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Task } from '../_models/task';
import { MessageService } from '../message.service';
import { ApiRequestTask, ApiRequestTasks } from "../_models/api-request-tasks";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class TaskService {

  private apiUrl = 'https://tfapi.lookfun.ru';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }

  /** GET tasks from the server */
  getTree (parent: number): Observable<ApiRequestTasks> {
    let url = this.apiUrl + "/tree/list?parent=" + parent;
    let ret = this.http.get<ApiRequestTasks>(url);
    return ret
      .pipe(
        tap(ret => this.log('fetched tasks')),
        catchError(this.handleError('getTasks', new ApiRequestTasks()))
      );
  }

  /** GET digest */
  getList (tid: number, branch: number): Observable<ApiRequestTasks> {
    let url = this.apiUrl + "/tasks/list/" + tid + "/" + branch;
    let ret = this.http.get<ApiRequestTasks>(url);
    return ret
      .pipe(
        tap(ret => this.log('fetched tasks')),
        catchError(this.handleError('getDigest', new ApiRequestTasks()))
      );
  }

  /** GET task by id. Return `undefined` when id not found */
  getHeroNo404<Data>(id: number): Observable<Task> {
    const url = `${this.apiUrl}/?id=${id}`;
    return this.http.get<Task[]>(url)
      .pipe(
        map(heroes => heroes[0]), // returns a {0|1} element array
        tap(h => {
          const outcome = h ? `fetched` : `did not find`;
          this.log(`${outcome} hero id=${id}`);
        }),
        catchError(this.handleError<Task>(`getHero id=${id}`))
      );
  }

  /** GET task by id. Will 404 if id not found */
  getTask(id: number): Observable<ApiRequestTask> {
    const url = `${this.apiUrl}/task/get/${id}`;
    return this.http.get<ApiRequestTask>(url).pipe(
      tap(_ => this.log(`fetched task id=${id}`)),
      catchError(this.handleError<any>(`getHero id=${id}`))
    );
  }

  /* GET tasks whose name contains search term */
  searchTasks(term: string): Observable<ApiRequestTasks> {
    if (!term.trim()) {
      // if not search term, return empty task array.
      return of(new ApiRequestTasks());
    }
    return this.http.get<ApiRequestTasks>(`${this.apiUrl}/tasks/search?term=${term}`).pipe(
      tap(_ => this.log(`found heroes matching "${term}"`)),
      catchError(this.handleError<any>('searchTasks', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new task to the server */
  addHero (hero: Task): Observable<Task> {
    return this.http.post<Task>(this.apiUrl, hero, httpOptions).pipe(
      tap((hero: Task) => this.log(`added hero w/ id=${hero.tid}`)),
      catchError(this.handleError<Task>('addHero'))
    );
  }

  /** DELETE: delete the task from the server */
  deleteTask (taskId: Task | number): Observable<any> {
    const id = typeof taskId === 'number' ? taskId : taskId.tid;
    const url = `${this.apiUrl}/task/delete/${id}`;

    return this.http.get<Task>(url, httpOptions).pipe(
      tap(_ => this.log(`deleted hero id=${id}`)),
      catchError(this.handleError<any>('deleteTask'))
    );
  }

  taskDone (taskId: Task | number): Observable<any> {
    const id = typeof taskId === 'number' ? taskId : taskId.tid;
    const url = `${this.apiUrl}/task/done/${id}`;

    return this.http.get<Task>(url, httpOptions).pipe(
      tap(_ => this.log(`task done id=${id}`)),
      catchError(this.handleError<any>('taskDone'))
    );
  }

  playTask(tid: number): Observable<any>  {
    return this.http.get<any>(`${this.apiUrl}/task/play/${tid}`, httpOptions).pipe(
        catchError(this.handleError<any>('playTask'))
    );
  }

  stopTask(tid: number): Observable<any>  {
    return this.http.get<Task>(`${this.apiUrl}/task/stop/${tid}`, httpOptions).pipe(
        catchError(this.handleError<any>('stopTask'))
    );
  }

  /** PUT: update the task on the server */
  updateTask (task: Task): Observable<ApiRequestTask> {
    return this.http.post(this.apiUrl + "/task/save", task, httpOptions).pipe(
      tap(_ => this.log(`updated task id=${task.tid}`)),
      catchError(this.handleError<any>('updateTask'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a TaskService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`TaskService: ${message}`);
  }
}
