import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { Task } from '../_models/task';
import { TaskService } from '../_services/task.service';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {
  tasks: Task[];
  currentTask: Task;
  taskInProgress: Task;
  currentTaskId: number;
  selectedMode: string;

  typeIcons = {
    'Event': 'event',
    'Task': 'adjust',
    'Folder': 'folder',
    'Project': 'business_center',
    'Pastime': 'brightness_7',
  };

  statusClasses = {
    'Closed': 'striked',
    'Puased': 'paused',
  };

  navigationSubscription;

  constructor(private router: Router,private route: ActivatedRoute, private taskService: TaskService) {

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.currentTaskId = +this.route.snapshot.paramMap.get('taskId');
        this.selectedMode = this.route.snapshot.paramMap.get('mode');
        this.getTasks();
      }
    });
  }

  ngOnInit() {
    this.currentTaskId = +this.route.snapshot.paramMap.get('taskId');
    this.selectedMode = this.route.snapshot.paramMap.get('mode');
    this.getTasks();
  }

  getTasks(): void {
    this.taskService.getTree(this.currentTaskId)
    .subscribe(ret => {
      this.tasks = [];

      ret.tasks.map((task: Task) => {
        this.tasks.push(new Task(task));
      });

      this.currentTask = new Task(ret.currentTask);
      if(ret.taskInProgress)
        this.taskInProgress = new Task(ret.taskInProgress);
    });
  }

  add(title: string, parent: number): void {
    title = title.trim();

    let newTask = new Task({title: title, parent: parent} as Task);

    if (!title) { return; }
    this.taskService.updateTask(newTask)
      .subscribe(ret => {
        this.tasks.push(ret.task);
      });
  }

  delete(task: Task): void {
    this.taskService.deleteTask(task.tid).subscribe(
        () => {
          this.router.navigate(["/tasks/" + task.parent + "/edit"]);
          this.getTasks();
        }
    );
  }

  done(task: Task): void {
    this.taskService.taskDone(task.tid).subscribe(
        () => {
          this.router.navigate(["/tasks/" + task.parent + "/edit"]);
          this.getTasks();
        }
    );
  }

  play(task: Task): void {
    this.taskService.playTask(task.tid).subscribe(
        () => {
          task.start = new Date().toISOString();
          task.finish = null;

          const objIndex = this.tasks.findIndex((_task => _task.tid === task.tid));
          this.tasks[objIndex] = task;
          this.taskInProgress = task;
        }
    );
  }

  onStopped(task: Task) {
    this.stop(task);
  }

  stop(task: Task): void {
    this.taskService.stopTask(task.tid).subscribe(
        () => {
          task.finish = new Date().toISOString();
          this.taskInProgress = null;
        }
    );
  }

  toggleSideNav(sidenav): void {
    if(window.innerWidth < 900)
      sidenav.toggle();
  }

}
